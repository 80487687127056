import React from 'react'
import SEO from '../components/seo/seo'
import Disclaimer from '../components/disclaimer/disclaimer'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

const EstatePlanningPage = () => (
  <>
    <SEO 
      title="Services" 
      description="Lawyer Jim Feleen provides estate planning and prepares wills, trusts, living wills and more in Claremont, NH. Call 603-862-6507"
      keywords={['lawyer', 'law office', 'attorney', 'James Feleen', 'Jim Feleen', 'Claremont', 'NH', 'New Hampshire', 'Claremont NH', 'estate', 'estate planning', 'wills', 'trusts']} />
    <Banner />
    <div className="body-wrapper">
      <h2>Estate Planning</h2>
      <h4>A well drafted estate plan or living will are important legal matters.</h4>
      <p>     
        We aim to guide our clients through estate planning in a logical, clear manner at reasonable cost, with the goal of providing only the appropriate documents our clients need and nothing more..
      </p>
      <p>
        Our law firm regularly recommends and prepares estate planning documents. If you are in need of a will, trust, power of attorney, advance directive, or a living will, our law office can help.
      </p>
      <p>
        The estate planning discussion can often include nursing home and Medicaid issues as they relate to potential asset protection. We are happy to explain the applicable rules and regulations in easy-to- understand terms. Our clients appreciate an opportunity to understand these issues as they apply to their particular situation.
      </p>
    </div>
    <Disclaimer />
  </>
)

const Banner = props => (
  <StaticQuery
    query={query}
    render={data => (
      <div className="body-wrapper">
        <Img alt="Hand signing the line on a legal document"
        className="hero-image" fluid={data.file.childImageSharp.fluid}/>
      </div>
    )}
  />
);

const query = graphql`
  query {
    file(relativePath: { eq: "signed-document-banner.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 90) {
          # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
            ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default EstatePlanningPage
