import { Link } from 'gatsby'
import React from 'react'

import './disclaimer.css'

const Disclaimer = props => (
    <div className="body-wrapper">
      <div className="disclaimer">
        <p>Use of this website and email links does not establish an attorney-client relationship.</p>
        <p>Please read the <Link to="/legal-disclaimer/">legal disclaimer</Link> and website terms of use.</p>
      </div>
    </div>
);

export default Disclaimer;
